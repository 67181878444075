import { set as _set } from 'lodash-es';

import { setTeaserPosition } from './helper/teaser';
import { _selector } from './helpers';
import General from './General';
import { pixelToEm } from '../util';

// const isFullHeightSidebar = (style) => {
//   if (['popup', 'sidebar'].includes(style.mode) && style.overlay.position !== 5 && style.overlay.fitToScreen) {
//     return true
//   }
//   return false
// }

export default (element, template) => {
  const _mq = {};

  const { desktop: _desktop, mobile: _mobile } = General(element, template);

  const { uid, type } = element;
  const selector = _selector(uid, type);

  const hasWheel = template.elements.some((el) => el.type === 'OmLuckyWheel');
  const mobileKeys = Object.keys(_mobile);

  if (
    _mobile[mobileKeys[0]] &&
    !hasWheel &&
    template.style.mode !== 'nano' &&
    template.style.mode !== 'embedded'
  ) {
    _mobile[mobileKeys[0]].width = { v: `${element.mobile.width}%`, i: true };
  }

  if (hasWheel) {
    _mobile[mobileKeys[0]].width = { v: '100%', i: true };
  }

  delete _desktop[selector].width;
  if (!element.desktop.width) {
    _set(_desktop, `["${selector}"].width`, {
      v: `100%`,
      i: template.style.mode !== 'nano',
    });
  } else {
    _set(_desktop, `["${selector}"].width`, {
      v: `${pixelToEm(element.desktop.width)}em`,
      i: template.style.mode !== 'nano',
    });
  }

  if (element.isTeaser) {
    setTeaserPosition({ element, _desktop, _mobile, selector, template });
  }

  const mediaQueries = [
    {
      maxWidth: 767,
      width: '100%',
      selector: `${selector}`,
    },
    {
      maxWidth: 576,
      width: 'auto',
      selector: `${selector}.om-teaser-canvas`,
    },
  ];

  if (hasWheel) {
    mediaQueries.forEach(({ maxWidth, width, selector }) => {
      const query = `["@media screen and (max-width: ${maxWidth}px)"].["${selector}"].width`;
      _set(_mq, query, {
        v: width,
        i: true,
      });
    });
  }

  return {
    desktop: _desktop,
    mobile: _mobile,
    mq: _mq,
  };
};
