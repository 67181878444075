import { get as _get, merge as _merge } from 'lodash-es';
import { _color, _simpleValue, _getTemplateColors } from '../helpers';
import { campaignScope } from '../../util';

export const setSwiperCss = (template, desktop, element) => {
  const hasSelectedBorder =
    _get(element, 'subElements.button.desktop.border.selectedBorder') !== 'no';

  const color = _color(
    _get(element, 'subElements.buttonText.desktop.color'),
    _getTemplateColors(template),
    _get(element, 'subElements.buttonText.desktop.color'),
  );

  const bcgColor = _color(
    _get(element, 'subElements.button.desktop.background.color'),
    _getTemplateColors(template),
    _get(element, 'subElements.button.desktop.background.color'),
  );

  const swiperCssAppearance = {
    [`${campaignScope} .om-swiper-button-prev`]: {
      color,
      'background-color': bcgColor,
      border: `2px ${_get(element, 'subElements.button.desktop.border.type')} ${_get(
        element,
        'subElements.button.desktop.border.color',
      )}`,
    },
    [`${campaignScope} .om-swiper-button-next`]: {
      color,
      'background-color': bcgColor,
      border: `2px ${_get(element, 'subElements.button.desktop.border.type')} ${_get(
        element,
        'subElements.button.desktop.border.color',
      )}`,
    },
  };

  for (const [selector, cssAttributes] of Object.entries(swiperCssAppearance)) {
    for (const [attribute, value] of Object.entries(cssAttributes)) {
      if (attribute !== 'border' || (attribute === 'border' && hasSelectedBorder)) {
        _merge(desktop, _simpleValue(null, null, value, attribute, selector));
      }
    }
  }
};
