import General from './General';
import SubGenerator from './SubGenerator';
import OmText from './OmText';
import OmInput from './OmInput';
import OmTextarea from './OmTextarea';
import OmImage from './OmImage';
import OmFeedback from './OmFeedback';
import OmPickAPresent from './OmPickAPresent';
import OmSocial from './OmSocial';
import OmLuckyWheel from './OmLuckyWheel';
import OmScratchCard from './OmScratchCard';
import OmRadioAndCheckbox from './OmRadioAndCheckbox';
import OmDropdown from './OmDropdown';
import OmPage from './OmPage';
import OmRow from './OmRow';
import OmButton from './OmButton';
import OmCoupon from './OmCoupon';
import OmSurvey from './OmSurvey';
import OmCol from './OmCol';
import OmProduct from './OmProduct';
import OmFloatingImage from './OmFloatingImage';

export default {
  General,
  SubGenerator,
  OmText,
  OmTextarea,
  OmInput,
  OmImage,
  OmFeedback,
  OmPickAPresent,
  OmSocial,
  OmLuckyWheel,
  OmScratchCard,
  OmRadio: OmRadioAndCheckbox,
  OmCheckbox: OmRadioAndCheckbox,
  OmDropdown,
  OmPage,
  OmRow,
  OmButton,
  OmCoupon,
  OmSurvey,
  OmCol,
  OmProduct,
  OmFloatingImage,
};
