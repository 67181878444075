import { merge as _merge } from 'lodash-es';

import * as generators from '../helpers';

const { _selector } = generators;

export default (subElement, subElementType, element, template) => {
  const _desktop = {};
  const _mobile = {};

  if (!subElement.selector || !subElement.selector.length) {
    if (subElementType !== 'shared') {
      console.warn(
        `skipping css generation, missing selector for ${element.type} ${subElementType}`,
      );
    }

    return {
      desktop: _desktop,
      mobile: _mobile,
    };
  }

  const { desktop, mobile } = subElement;
  const selector = `${_selector(element.uid, element.type, true)} ${subElement.selector}`;

  if (desktop) {
    Object.keys(desktop).forEach((prop) => {
      const generator = generators[prop];

      if (generator) {
        const styles = generator(null, element.type, desktop[prop], false, template, selector);

        _merge(_desktop, styles);
      }
    });
  }

  if (mobile) {
    Object.keys(mobile).forEach((prop) => {
      const generator = generators[prop];

      if (generator) {
        const styles = generator(null, element.type, mobile[prop], true, template, selector);

        _merge(_mobile, styles);
      }
    });
  }

  return {
    desktop: _desktop,
    mobile: _mobile,
  };
};
