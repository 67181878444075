import { merge as _merge } from 'lodash-es';
import * as helpers from '../helpers';

export default (style, template) => {
  const selector = '#om-campaign-0 .om-ribbon-backgroundColor';
  const type = 'ribbon';
  const _desktop = {};

  // GENERATE REGULAR RULES
  for (const key in style) {
    const generator = helpers[key];
    if (!generator) continue;
    const value = style[key];

    _merge(_desktop, generator(type, type, value, false, template, selector));
  }

  return {
    desktop: _desktop,
    mobile: {},
  };
};
