import { merge as _merge } from 'lodash-es';
import {
  _selector,
  _simpleEmTransform,
  width,
  height,
  font,
  margin,
  padding,
  fontFamily,
  color,
  _simpleValue,
  _color,
  _getTemplateColors,
} from './helpers';
import General from './General';

export default (element, template) => {
  const { desktop: _desktop, mobile: _mobile } = General(element, template);

  const { uid, type } = element;

  const generateBoxRules = () => {
    const colorProperties = ['boxColor', 'ribbonColor', 'shadowColor'];
    const svgClasses = ['box', 'ribbon', 'shadow'];

    const rules = [];

    for (let i = 0; i < colorProperties.length; i++) {
      const colorProperty = colorProperties[i];
      const svgClass = svgClasses[i];

      rules.push(
        _simpleValue(
          uid,
          type,
          _color(element.desktop.pickAPresent[colorProperty], _getTemplateColors(template)),
          'fill',
          `${_selector(uid, type)} .om-present .om-present-svg-${svgClass}-color`,
        ),
      );
    }

    return rules;
  };

  const generateTitleRules = (type) => {
    return [
      ...font(
        uid,
        type,
        element.desktop[type],
        false,
        template,
        `${_selector(uid, type)} .om-pick-a-present-${type} div`,
      ),
      margin(
        uid,
        type,
        element.desktop[type].margin,
        false,
        template,
        `${_selector(uid, type)} .om-pick-a-present-${type}`,
        false,
      ),
      padding(
        uid,
        type,
        element.desktop[type].padding,
        false,
        template,
        `${_selector(uid, type)} .om-pick-a-present-${type}`,
      ),
    ];
  };

  const couponRules = [
    fontFamily(
      uid,
      type,
      element.desktop.title.fontFamily,
      false,
      template,
      `${_selector(uid, type)} .om-present-coupon-holder div`,
    ),
    color(
      uid,
      type,
      element.desktop.title.color,
      false,
      template,
      `${_selector(uid, type)} .om-present-coupon-holder div`,
    ),
  ];

  const iconRules = (device) => [
    width(
      uid,
      type,
      element[device].icon.size,
      device === 'mobile',
      template,
      `${_selector(uid, type)} .om-pick-a-present .om-present svg`,
    ),
    height(
      uid,
      type,
      element[device].icon.size,
      device === 'mobile',
      template,
      `${_selector(uid, type)} .om-pick-a-present .om-present svg`,
    ),
    _simpleEmTransform(
      uid,
      type,
      element[device].icon.horizontalSpacing,
      'margin-right',
      `${_selector(uid, type)} .om-pick-a-present .om-present`,
    ),
  ];

  const containerRules = [
    height(
      uid,
      type,
      element.desktop.size,
      false,
      template,
      `${_selector(uid, type)} .om-pick-a-present`,
    ),
  ];

  _merge(
    _desktop,
    ...generateBoxRules(),
    ...generateTitleRules('title'),
    ...generateTitleRules('subtitle'),
    ...couponRules,
    ...iconRules('desktop'),
    ...containerRules,
  );

  _merge(_mobile, ...iconRules('mobile'));

  return {
    desktop: _desktop,
    mobile: _mobile,
  };
};
