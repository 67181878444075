import { set as _set } from 'lodash-es';
import OmImage from './OmImage';
import { campaignScope, _pixelToEm } from './helpers';

export default (element, template) => {
  const { desktop: _desktop, mobile: _mobile } = OmImage(element, template);

  const { uid } = element;

  const selector = `${campaignScope} #${uid}_wrapper`;

  _set(_desktop, `["${selector}"].position`, { v: 'absolute' });

  if (element.desktop.position.left && element.desktop.position.top) {
    _set(_desktop, `["${selector}"].left`, { v: `${element.desktop.position.left}%` });
    _set(_desktop, `["${selector}"].top`, { v: `${element.desktop.position.top}%` });
  } else if (element.desktop.position.x && element.desktop.position.y) {
    _set(_desktop, `["${selector}"].left`, { v: `${_pixelToEm(element.desktop.position.x)}em` });
    _set(_desktop, `["${selector}"].top`, { v: `${_pixelToEm(element.desktop.position.y)}em` });
  }

  _set(_desktop, `["${selector}"].width`, {
    v: `${_pixelToEm(element.desktop.smartSize.width)}em`,
  });

  if (element.mobile.position.left) {
    _set(_mobile, `["${selector}"].left`, { v: `${element.mobile.position.left}%` });
  } else if (element.mobile.position.x) {
    _set(_mobile, `["${selector}"].left`, { v: `${_pixelToEm(element.mobile.position.x)}em` });
  }

  if (element.mobile.position.top) {
    _set(_mobile, `["${selector}"].top`, { v: `${element.mobile.position.top}%` });
  } else if (element.mobile.position.y) {
    _set(_mobile, `["${selector}"].top`, { v: `${_pixelToEm(element.mobile.position.y)}em` });
  }

  if (element.mobile.smartSize.width) {
    _set(_mobile, `["${selector}"].width`, {
      v: `${_pixelToEm(element.mobile.smartSize.width)}em`,
    });
  }

  return {
    desktop: _desktop,
    mobile: _mobile,
  };
};
