import { merge as _merge } from 'lodash-es';
import { _selector, fontSize, font } from './helpers';
import General from './General';

export default (element, template) => {
  const { desktop: _desktop, mobile: _mobile } = General(element, template);

  const { uid, type } = element;

  _merge(
    _desktop,
    fontSize(
      uid,
      type,
      element.desktop.iconSize,
      false,
      template,
      `${_selector(uid, type)} .service-url`,
    ),
    ...font(uid, type, element.desktop.link, false, template, `${_selector(uid, type)} .link-text`),
  );

  // _merge(
  //   _mobile,
  //   ...iconRules('mobile')
  // )

  return {
    desktop: _desktop,
    mobile: _mobile,
  };
};
