import { get as _get, set as _set, merge as _merge } from 'lodash-es';
import General from './General';
import {
  _selector,
  fontSize,
  fontFamily,
  _pixelToEm,
  background,
  color,
  border,
  shadow,
} from '../helpers';

export default (subElement, subElementType, element, template) => {
  const { desktop, mobile } = General(subElement, subElementType, element, template);

  const { uid, type } = element;
  const baseSelector = _selector(uid, type);
  const selector = `${baseSelector} ${subElement.selector}`;

  if (['number', 'separator', 'label'].includes(subElementType)) {
    let desktopFontSizeValue = _get(element, 'subElements.shared.desktop.fontSize');
    const mobileFontSizeValue =
      _get(element, 'subElements.shared.mobile.fontSize') || desktopFontSizeValue;

    if (subElementType === 'separator') {
      const desktopColor = _get(element, 'subElements.separator.desktop.color');
      _merge(mobile, color(uid, type, desktopColor, false, template, selector));
    }

    if (subElementType === 'label') {
      if (desktopFontSizeValue > 48) {
        desktopFontSizeValue = `${_pixelToEm(desktopFontSizeValue / 2.125)}em`;
      } else {
        desktopFontSizeValue = '1em';
      }

      _set(desktop, `["${selector}"].font-size.v`, desktopFontSizeValue);
      _set(mobile, `["${selector}"].font-size.v`, `${_pixelToEm(mobileFontSizeValue)}em`);
    } else if (subElementType === 'number') {
      _merge(
        desktop,
        fontSize(uid, type, desktopFontSizeValue, false, template, `${selector} span`),
      );
      _merge(mobile, fontSize(uid, type, mobileFontSizeValue, false, template, `${selector} span`));
    } else {
      _merge(desktop, fontSize(uid, type, desktopFontSizeValue, false, template, selector));
      _merge(mobile, fontSize(uid, type, mobileFontSizeValue, false, template, selector));
    }
  }

  if (['number', 'label'].includes(subElementType)) {
    const desktopFontFamily = _get(element, 'subElements.shared.desktop.fontFamily');
    const mobileFontFamily =
      _get(element, 'subElements.shared.mobile.fontFamily') || desktopFontFamily;

    if (subElementType === 'number') {
      const desktopBackgroundColor = _get(element, 'subElements.number.desktop.background');
      _merge(mobile, background(uid, type, desktopBackgroundColor, false, template, selector));

      const desktopBorder = _get(element, 'subElements.number.desktop.border');
      _merge(mobile, border(uid, type, desktopBorder, false, template, selector));
      const desktopShadow = _get(element, 'subElements.number.desktop.shadow');
      _merge(mobile, shadow(uid, type, desktopShadow, false, template, selector));
    }

    const desktopColor = _get(element, `subElements.${subElementType}.desktop.color`);

    _merge(desktop, fontFamily(uid, type, desktopFontFamily, false, template, selector));
    _merge(mobile, fontFamily(uid, type, mobileFontFamily, false, template, selector));
    _merge(mobile, color(uid, type, desktopColor, false, template, selector));
  }

  return {
    desktop,
    mobile,
  };
};
