import { get as _get } from 'lodash-es';
import { set as _set } from 'lodash-es';
import { merge as _merge } from 'lodash-es';
import * as generators from '../helpers';
import { cssFourValueResolve } from '../../util';

const { _selector, justifyContent, _pixelToEm, BASE_FONT_SIZE } = generators;

export const getWrapperRules = (element, template) => {
  const desktop = {};
  const mobile = {};

  const { uid, type } = element;
  const wrapperSelector = `${_selector(uid, type)}_wrapper .om-inputs-wrapper`;

  if (element.desktop.smartSize.type === '100w') {
    _set(desktop, `["${wrapperSelector}"].width.v`, '100%');
  }

  if (element.mobile.smartSize.type === '100w') {
    _set(mobile, `["${wrapperSelector}"].width.v`, '100%');
  }

  _merge(
    desktop,
    justifyContent(
      uid,
      type,
      element.desktop.justifyContent,
      false,
      template,
      wrapperSelector,
      element.desktop.smartSize.type !== '100w',
    ),
  );

  _merge(
    mobile,
    justifyContent(
      uid,
      type,
      element.mobile.justifyContent,
      true,
      template,
      wrapperSelector,
      element.mobile.smartSize.type !== '100w',
    ),
  );

  return {
    desktop,
    mobile,
  };
};

export const getPlaceholderRules = (element, template) => {
  const desktop = {};
  const mobile = {};

  const { uid, type } = element;

  const placeholderSelector = `${_selector(uid, type)} input:placeholder`;
  const placeholderGens = ['fontFamily', 'textAlign', 'color', 'fontSize'];

  placeholderGens.forEach((gen) => {
    _merge(
      desktop,
      generators[gen](uid, type, element.desktop[gen], template, false, placeholderSelector),
    );
    _merge(
      mobile,
      generators[gen](uid, type, element.mobile[gen], template, true, placeholderSelector),
    );
  });

  return {
    desktop,
    mobile,
  };
};

export function calculateCorrectSize(element, property, isMobile) {
  const device = isMobile ? 'mobile' : 'desktop';
  const fbValue = _get(element, `desktop.${property}`, 10) || 10;
  const fbFontSize = _get(element, 'desktop.fontSize', 10) || BASE_FONT_SIZE;
  const value = parseInt(
    _get(element, `${device}.${property}`, isMobile ? _get(element, `desktop.${property}`) : 0),
    10,
  );
  const fontSize = parseInt(
    _get(
      element,
      `${device}.fontSize`,
      isMobile ? _get(element, 'desktop.fontSize', 10) : BASE_FONT_SIZE,
    ),
    10,
  );

  return (value || fbValue) * (BASE_FONT_SIZE / (fontSize || fbFontSize));
}

export function correctHeight(selector, element, styles, isImportant = true) {
  const correctInputHeightDesktop = calculateCorrectSize(element, 'height');
  _set(styles.desktop, `${selector}.height.v`, `${_pixelToEm(correctInputHeightDesktop)}em`);

  const correctInputHeightMobile = calculateCorrectSize(element, 'height', true);
  _set(styles.mobile, `${selector}.height.v`, `${_pixelToEm(correctInputHeightMobile)}em`);

  if (isImportant) {
    _set(styles.desktop, `${selector}.height.i`, true);
    _set(styles.mobile, `${selector}.height.i`, true);
  }

  return styles;
}

export function correctTextAlign(selector, element, styles) {
  const textAlign = _get(styles.desktop, `${selector}.text-align.v`);
  let justifyContent = '';
  switch (textAlign) {
    case 'left':
      justifyContent = 'flex-start';
      break;
    case 'center':
      justifyContent = 'center';
      break;
    case 'right':
      justifyContent = 'flex-end';
      break;
  }
  _set(styles.desktop, `${selector}.justify-content.v`, justifyContent);
}

export function correctBorder(selector, element, styles) {
  const border = _get(element, 'desktop.border.selectedBorder');
  if (border) {
    const correctBorderWidthDesktop = calculateCorrectSize(element, 'border.width');
    _set(
      styles.desktop,
      `${selector}.border-width.v`,
      cssFourValueResolve(border, _pixelToEm(correctBorderWidthDesktop), 'em'),
    );
  }

  const mobileBorder = _get(element, 'mobile.border.selectedBorder');
  if (mobileBorder) {
    const correctBorderWidthMobile = calculateCorrectSize(element, 'border.width', true);
    _set(
      styles.mobile,
      `${selector}.border-width.v`,
      cssFourValueResolve(mobileBorder, _pixelToEm(correctBorderWidthMobile), 'em'),
    );
  }
}

export function correctSize(selector, element, styles, isImportant = true) {
  const sizeTypeDesktop = _get(element, 'desktop.smartSize.type');
  if (sizeTypeDesktop === 'manual') {
    const correctInputWidthDesktop = calculateCorrectSize(element, 'smartSize.width');
    _set(styles.desktop, `${selector}.width.v`, `${_pixelToEm(correctInputWidthDesktop)}em`);
  }

  const sizeTypeMobile = _get(element, 'mobile.smartSize.type');
  if (sizeTypeMobile === 'manual') {
    const correctInputWidthMobile = calculateCorrectSize(element, 'smartSize.width', true);
    _set(styles.mobile, `${selector}.width.v`, `${_pixelToEm(correctInputWidthMobile)}em`);
  }

  if (isImportant) {
    _set(styles.desktop, `${selector}.width.i`, true);
  }

  if (isImportant && sizeTypeMobile !== 'default') {
    _set(styles.mobile, `${selector}.width.i`, true);
  }
}

export function unsetMargin(selector, styles) {
  _set(styles.desktop, `${selector}.margin.v`, `unset`);
  _set(styles.mobile, `${selector}.margin.v`, `unset`);
}
