import General from './General';

export default (subElement, subElementType, element, template) => {
  const { desktop, mobile } = General(subElement, subElementType, element, template);

  const maxWidth = element.subElements?.content?.mobile?.maxWidth;
  const mobileKeys = Object.keys(mobile);

  if (mobile[mobileKeys[0]] && maxWidth) {
    if (template.style.mode === 'fullscreen') {
      mobile[mobileKeys[0]]['max-width'] = {
        v: `${maxWidth}%`,
        i: true,
      };
    } else {
      delete mobile[mobileKeys[0]]['max-width'];
    }
  }

  return {
    desktop,
    mobile,
  };
};
