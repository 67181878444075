import { merge as _merge } from 'lodash-es';
import * as helpers from '../helpers';
import { isInCenterPosition } from '../../util';

export default (style, template) => {
  const selOverlay = '#om-campaign-0 .om-overlay';
  const selOuterCanvas = '#om-campaign-0 .om-outer-canvas';
  const selCanvas = '#om-campaign-0 .om-canvas';
  const uid = 'overlay';
  const type = 'global';

  let _desktop = {};
  let _mobile = {};

  if (style.fitToScreen && style.position !== 5) {
    _desktop = {
      [selOverlay]: { height: { v: '100%', i: true } },
      [selOuterCanvas]: {
        height: { v: '100%' },
        padding: { v: '0', i: true },
      },
      [selCanvas]: {
        height: { v: '100%' },
        display: { v: 'flex' },
        'flex-wrap': { v: 'wrap' },
        'justify-content': { v: style.alignItems, i: true },
        'justify-items': { v: style.alignItems, i: true },
      },
    };
  }
  if (style.fitToScreenOnMobile && style.position !== 5) {
    _mobile = {
      [selOverlay]: { height: { v: '100%', i: true } },
      [selOuterCanvas]: {
        height: { v: '100%' },
        padding: { v: '0', i: true },
      },
      [selCanvas]: {
        height: { v: '100%' },
        display: { v: 'flex' },
        'flex-wrap': { v: 'wrap' },
        'justify-content': { v: style.alignItems },
        'justify-items': { v: style.alignItems },
      },
    };
  }

  if (template.style.mode === 'embedded' && style.container) {
    _desktop = {
      [selOuterCanvas]: {
        display: { v: 'flex' },
        'justify-content': { v: style.container },
      },
    };
  }

  if (!isInCenterPosition(style)) {
    _merge(_desktop, helpers.margin(uid, type, style.margin, false, template, selOverlay));
    _merge(_mobile, helpers.background(uid, type, style.background, false, template, selOverlay));
  }

  if (isInCenterPosition(style) || (!isInCenterPosition(style) && style.withOverlay)) {
    _merge(_desktop, helpers.background(uid, type, style.background, false, template, selOverlay));
    _merge(_mobile, helpers.background(uid, type, style.background, false, template, selOverlay));
  }

  return {
    desktop: _desktop,
    mobile: _mobile,
  };
};
