import { get as _get, set as _set, merge as _merge } from 'lodash-es';
import General from './General';
import { _selector, justifyContent, alignItems } from './helpers';
import { pixelToEm } from '../util';

const setSpaceBetweenOptionsHorizontally = (rules, selector, element, device) => {
  _set(rules, `["${selector}"].margin-bottom`, {
    v: `${pixelToEm(element[device].spaceBetweenOptions)}em`,
  });
  _set(rules, `["${selector}:last-child"].margin-bottom`, { v: 0, i: true });
};

const baseFluidRules = (rules, selector, element, device) => {
  _set(rules, `["${selector}"].margin-right`, {
    v: `${pixelToEm(element[device].spaceBetweenOptions)}em`,
  });
  _set(rules, `["${selector}"].margin-top`, {
    v: `${pixelToEm(element[device].spaceBetweenOptions / 2)}em`,
  });
  _set(rules, `["${selector}"].margin-bottom`, {
    v: `${pixelToEm(element[device].spaceBetweenOptions / 2)}em`,
  });
};

const removeMarginRight = (position, rules, selector) => {
  if (position === 'flex-end') {
    _set(rules, `["${selector}"].margin-right`, {
      v: `unset`,
    });
  }
};

export default (element, template) => {
  const { desktop: _desktop, mobile: _mobile } = General(element, template);

  const { uid, type } = element;

  const selector = `${_selector(uid, type)} .om-survey-option`;

  const devices = ['desktop', 'mobile'];

  for (const device of devices) {
    const rules = {};

    const sizeType = _get(element, `subElements.option.${device}.smartSize.type`);
    const position = _get(element, `${device}.justifyContent`);

    if (sizeType === 'fluid') {
      _set(rules, `["${_selector(uid, type)}"].flex-direction`, { v: 'row' });
    }

    if (_get(element, `${device}.spaceBetweenOptions`)) {
      if (sizeType === 'fluid') {
        if (position === 'center') {
          _set(rules, `["${`${_selector(uid, type)}`}"].margin-left`, {
            v: `-${pixelToEm(element[device].spaceBetweenOptions)}em`,
          });
          _set(rules, `["${`${_selector(uid, type)}`}"].margin-right`, {
            v: `-${pixelToEm(element[device].spaceBetweenOptions)}em`,
          });
        } else {
          _set(rules, `["${`${_selector(uid, type)}`}"].margin-left`, {
            v: `0`,
          });
          _set(rules, `["${`${_selector(uid, type)}`}"].margin-right`, {
            v: `0`,
          });
        }
        if (position === 'center' || position === 'flex-end') {
          _set(rules, `["${selector}"].margin-left`, {
            v: `${pixelToEm(element[device].spaceBetweenOptions)}em`,
          });
        }
        baseFluidRules(rules, selector, element, device);
        removeMarginRight(position, rules, selector);
      } else if (sizeType === 'manual') {
        if (element.desktop.orientation) {
          setSpaceBetweenOptionsHorizontally(rules, selector, element, device);
        } else {
          _set(rules, `["${selector}"].margin-left`, {
            v: `${pixelToEm(element[device].spaceBetweenOptions) / 2}em`,
          });
          _set(rules, `["${selector}"].margin-right`, {
            v: `${pixelToEm(element[device].spaceBetweenOptions) / 2}em`,
          });
          _set(rules, `["${selector}"].margin-top`, { v: '0.625em' });
          _set(rules, `["${selector}"].margin-bottom`, { v: '0.625em' });
          _set(rules, `["${`${_selector(uid, type)}`}"].margin-left`, {
            v: `-${pixelToEm(element[device].spaceBetweenOptions) / 2}em`,
          });
          _set(rules, `["${`${_selector(uid, type)}`}"].margin-right`, {
            v: `-${pixelToEm(element[device].spaceBetweenOptions) / 2}em`,
          });
        }
      } else {
        setSpaceBetweenOptionsHorizontally(rules, selector, element, device);
      }
    }

    if (sizeType === 'fluid') {
      _merge(rules, justifyContent(uid, type, element[device].justifyContent, false, template));
    } else {
      _merge(rules, alignItems(uid, type, element[device].justifyContent, false, template));
    }

    _merge(device === 'desktop' ? _desktop : _mobile, rules);
  }

  return {
    desktop: _desktop,
    mobile: _mobile,
  };
};
