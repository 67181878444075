import { isEmpty, merge as _merge } from 'lodash-es';
import global from './global/index';
import { fontSizeOptions } from '../../../quill_options';

const globals = ['overlay', 'tab', 'closeButton', 'teaserCloseButton', 'ribbon'];

export const generatePaletteColorsClasses = (palette, target) => {
  if (!palette) return {};
  const scope = target;
  const rules = {
    [scope]: {},
    [`${scope} .om-color-palette-main`]: { color: { v: 'var(--om-color-0)', i: true } },
  };

  palette.forEach((v, i) => {
    rules[scope][`--om-color-${i}`] = { v };
    rules[`${scope} .om-color-palette-c${i}`] = {
      color: { v: `var(--om-color-${i})`, i: true },
    };
    rules[`${scope} .om_color_${i + 1}`] = {
      color: { v: `var(--om-color-${i})`, i: true },
    };
  });

  return rules;
};

const WEBSAFE_FONTS = [{ family: 'Verdana, Geneva, sans-serif', key: 'verdana' }];

const generateThemeKitFontClasses = () => {
  // eslint-disable-next-line
  const inSSR = typeof __vue_store__ !== 'undefined';
  // eslint-disable-next-line
  const store = inSSR ? __vue_store__ : window.om.store;
  if (!store) return {};

  const installedFonts = store.getters.installedFonts;

  WEBSAFE_FONTS.forEach(({ key, family }) => {
    installedFonts.push({
      key,
      family,
      subsets: [],
      variants: [],
      preInstalled: true,
      weights: ['100', '200', '300', '400', '500', '600', '700', '800', '900'],
    });
  });

  const themeKitFontFamilies = store.state.template?.themeKit?.fonts?.map((themeFont) => {
    const font = installedFonts.find((font) => font.key === themeFont);
    if (!font) {
      if (inSSR) {
        console.log(
          `Font not found in SSR, userId: ${store.state.databaseId}, variantId: ${store.state.variantId}, font: ${themeFont}`,
        );
      }
      return null;
    }

    return font.family;
  });

  const scope = '#om-campaign-0';
  const rules = {
    [scope]: {},
  };

  if (!themeKitFontFamilies) return;

  themeKitFontFamilies.forEach((v, i) => {
    if (!v) return;
    rules[scope][`--om-font-${i}`] = { v: `'${v}'` };
    rules[`${scope} .om_font_${i + 1}`] = {
      'font-family': { v: `var(--om-font-${i})`, i: true },
    };
  });

  return rules;
};

const BASE_FONT_SIZE = 16;

const fontSizeToCss = (style) => {
  const { button, text, tab } = style;
  const fontSize = text.size || BASE_FONT_SIZE;
  const multiplier = BASE_FONT_SIZE / fontSize;

  const buttonFontSize = button.fontSize || BASE_FONT_SIZE;
  const buttonMultiplier = BASE_FONT_SIZE / buttonFontSize;

  const teaserFontSize = tab.fontSize || BASE_FONT_SIZE;
  const teaserMultiplier = BASE_FONT_SIZE / teaserFontSize;

  const rules = {};

  fontSizeOptions.forEach((quillFont) => {
    const selector = `#om-campaign-0 .om-fontsize-${quillFont.px}`;
    const btnSelector = `#om-campaign-0 .om-button-fontsize-${quillFont.px}`;
    const tabSelector = `#om-campaign-0 .om-teaser-fontsize-${quillFont.px}`;
    const emValue = (quillFont.px / BASE_FONT_SIZE) * multiplier;
    const buttonEmValue = (quillFont.px / BASE_FONT_SIZE) * buttonMultiplier;
    const teaserEmValue1 = (quillFont.px / BASE_FONT_SIZE) * teaserMultiplier;

    _merge(rules, {
      [selector]: { 'font-size': { v: `${emValue}em` } },
      [btnSelector]: { 'font-size': { v: `${buttonEmValue}em` } },
      // [txtSelector]: { 'font-size': { v: `${textEmValue}em` } },
      [tabSelector]: { 'font-size': { v: `${teaserEmValue1}em` } },
    });
  });

  return rules;
};

const _handleQuillThemeKitFonts = (themeKitFonts) => {
  if (!themeKitFonts) return {};

  return themeKitFonts.map((_, index) => {
    return {
      [`#om-campaign-0 .ql-font-om-font-${index + 1}`]: {
        'font-family': { v: `var(--om-font-${index})` },
      },
    };
  });
};

const getFontFamilies = () => {
  const rules = {};
  // eslint-disable-next-line
  const store = typeof __vue_store__ !== 'undefined' ? __vue_store__ : window.om.store;
  if (!store) return rules;
  const inDocker = store.state.docker;
  const installedFonts = store.getters.installedFonts;
  installedFonts.forEach(({ key, family }) => {
    const wrappedFamily = /\s/.test(family) ? `'${family}'` : family;
    if ((inDocker && store.state.usedFonts.includes(key)) || !inDocker) {
      const value = { 'font-family': { v: wrappedFamily } };

      rules[`#om-campaign-0 .ql-font-${key}`] = value;

      if (!inDocker) {
        rules[`.ql-picker-item[data-value="${key}"]`] = value;
      }
    }
  });

  const themeKitFonts = store.state.template?.themeKit?.fonts;
  if (themeKitFonts) _merge(rules, ..._handleQuillThemeKitFonts(themeKitFonts));
  return rules;
};

export default (template) => {
  const _desktop = {};
  const _mobile = {};
  let themeColorsMerged = template?.palette;
  if (
    template?.style.palette?.themeColors &&
    !isEmpty(template?.style.palette?.themeColors) &&
    themeColorsMerged
  ) {
    const colors = template.style.palette.themeColors.map((color) => color.themeColor);
    themeColorsMerged = [...themeColorsMerged, ...colors];
  }
  _merge(_desktop, generatePaletteColorsClasses(themeColorsMerged, '#om-campaign-0'));
  _merge(_desktop, fontSizeToCss(template.style));
  _merge(_desktop, getFontFamilies());
  _merge(_desktop, generateThemeKitFontClasses());

  globals.forEach((globalKey) => {
    const generator = global[globalKey];
    if (!generator) return;

    const { desktop, mobile } = generator(template.style[globalKey], template);

    _merge(_desktop, desktop);
    _merge(_mobile, mobile);
  });

  return {
    desktop: _desktop,
    mobile: _mobile,
  };
};
