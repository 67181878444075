import overlay from './overlay';
import tab from './tab';
import closeButton from './closeButton';
import teaserCloseButton from './teaserCloseButton';
import ribbon from './ribbon';

export default {
  overlay,
  tab,
  closeButton,
  teaserCloseButton,
  ribbon,
};
