import { merge as _merge } from 'lodash-es';
import * as generators from './helpers';
import General from './General';
import {
  getWrapperRules,
  getPlaceholderRules,
  correctHeight,
  correctBorder,
  correctSize,
} from './shared/input';

const { _selector } = generators;

export default (element, template) => {
  const { uid, type } = element;
  const selector = _selector(uid, type, false);

  const { desktop: _desktop, mobile: _mobile } = General(element, template);

  const styles = { mobile: _mobile, desktop: _desktop };
  correctHeight(selector, element, styles);
  correctBorder(selector, element, styles);
  correctSize(selector, element, styles);

  const placeholderRules = getPlaceholderRules(element, template);
  _merge(_desktop, placeholderRules.desktop);
  _merge(_mobile, placeholderRules.mobile);

  const wrapperRules = getWrapperRules(element, template);
  _merge(_desktop, wrapperRules.desktop);
  _merge(_mobile, wrapperRules.mobile);

  return {
    desktop: _desktop,
    mobile: _mobile,
  };
};
