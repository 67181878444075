import { merge as _merge } from 'lodash-es';
import { _selector, _simpleValue } from './helpers';
import General from './General';
import { getWrapperRules, correctHeight, correctBorder, correctSize } from './shared/input';

export default (element, template) => {
  const { desktop: _desktop, mobile: _mobile } = General(element, template);

  const { uid, type } = element;
  const selector = _selector(uid, type);
  const styles = { mobile: _mobile, desktop: _desktop };

  correctHeight(selector, element, styles);
  correctBorder(selector, element, styles);
  correctSize(selector, element, styles);

  _merge(_desktop, _simpleValue(uid, type, element.desktop.textAlign, 'text-align-last', selector));

  _merge(_mobile, _simpleValue(uid, type, element.mobile.textAlign, 'text-align-last', selector));

  const wrapperRules = getWrapperRules(element, template);
  _merge(_desktop, wrapperRules.desktop);
  _merge(_mobile, wrapperRules.mobile);

  return {
    desktop: _desktop,
    mobile: _mobile,
  };
};
