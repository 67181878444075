import { merge as _merge } from 'lodash-es';
import * as generators from './subElements';

export default (element, template) => {
  const { type, subElements } = element;
  const _desktop = {};
  const _mobile = {};

  Object.entries(subElements).forEach(([subElementType, subElement]) => {
    const generator = generators[type] || generators.General;

    if (generator) {
      const { desktop, mobile } = generator(subElement, subElementType, element, template);

      _merge(_desktop, desktop);
      _merge(_mobile, mobile);
    }
  });

  return {
    desktop: _desktop,
    mobile: _mobile,
  };
};
