import { merge as _merge, set as _set } from 'lodash-es';

import * as generators from './helpers';
import General from './General';
import {
  getWrapperRules,
  correctHeight,
  correctBorder,
  correctSize,
  correctTextAlign,
} from './shared/input';

const { _selector, margin } = generators;

function getPickerRules(element, template) {
  let rules = {};

  const {
    uid,
    type,
    desktop: { height },
    data: { displayType },
  } = element;

  const selector = _selector(uid, type);
  const inputType = element.data.form.customSettings.type;

  let checkedColor;
  if (Number.isInteger(element.desktop.checkedColor)) {
    let colors = [];
    if (template.palette) {
      colors = [...template.palette];
    }
    if (template.style?.palette?.themeColors) {
      colors = [
        ...colors,
        ...template.style.palette.themeColors.map(({ themeColor }) => themeColor),
      ];
    }
    checkedColor = colors[element.desktop.checkedColor];
  } else {
    checkedColor = element.desktop.checkedColor;
  }

  switch (displayType) {
    case 'normal':
      rules = {
        [`${selector} input:checked ~ label:after`]: {
          color: {
            v: checkedColor,
          },
        },
      };
      if (inputType === 'radio') {
        rules[`${selector}  input ~ label:before`] = {
          'box-shadow': {
            v: 'inset 0 8px 8px #c5c5c5',
          },
        };

        rules[`${selector} input:checked ~ label:after`] = {
          background: {
            v: `${checkedColor}`,
          },
          border: {
            v: '1px solid #d5d5d5',
          },
        };
      }
      break;
    case 'colorBorder':
      rules = {
        [`${selector} input:checked ~ label:before`]: {
          'border-color': {
            v: checkedColor,
          },
        },
        [`${selector} input:checked ~ label:after`]: {
          color: {
            v: checkedColor,
          },
        },
      };
      if (inputType === 'radio') {
        rules[`${selector} input ~ label:before`] = {
          'border-color': {
            v: checkedColor,
          },
        };

        rules[`${selector} input:checked ~ label:after`] = {
          background: {
            v: checkedColor,
          },
        };
      }
      break;
    case 'round':
      rules = {
        [`${selector} input ~ label:before`]: {
          'border-radius': {
            v: '100%',
          },
        },
        [`${selector} input:checked ~ label:before`]: {
          background: {
            v: checkedColor,
          },
          border: {
            v: 0,
          },
        },
        [`${selector} input:checked ~ label:after`]: {
          color: {
            v: 'white',
          },
        },
      };
      break;
    case 'background':
      rules = {
        [`${selector} input:checked ~ label:before`]: {
          background: {
            v: 'linear-gradient(180deg, rgba(233,233,233,1) 0%, rgba(247,247,247,1) 65%)',
          },
        },
        [`${selector} input:checked ~ label:after`]: {
          color: {
            v: checkedColor,
          },
        },
      };
      if (inputType === 'checkbox') {
        rules[`${selector} input ~ label:before`] = {
          'box-shadow': {
            v: 'inset 0 8px 8px #c5c5c5',
          },
        };
      }
      break;
    case 'full':
      rules = {
        [`${selector} input:checked ~ label:before`]: {
          background: {
            v: checkedColor,
          },
          border: 0,
        },
        [`${selector} input:checked ~ label:after`]: {
          color: {
            v: 'white',
          },
        },
      };
      if (inputType === 'radio') {
        rules[`${selector} input:checked ~ label:after`] = {
          background: {
            v: checkedColor,
          },
          'box-shadow': {
            v: 'none',
          },
        };
      }
      if (inputType === 'checkbox') {
        rules[`${selector} input ~ label:before`] = {
          'border-color': {
            v: checkedColor,
          },
        };
      }
      break;
    case 'thickBorder':
      rules = {
        [`${selector} input:checked ~ label:after`]: {
          background: {
            v: 'white',
          },
          'box-shadow': {
            v: `inset 0 0 0 4px ${checkedColor}`,
          },
        },
      };
      break;
    case 'check':
      rules = {
        [`${selector} input ~ label:before`]: {
          'border-color': {
            v: checkedColor,
          },
        },
        [`${selector} input:checked ~ label:after`]: {
          color: {
            v: 'white',
          },
          background: {
            v: checkedColor,
          },
          'box-shadow': {
            v: 'none',
          },
          content: {
            v: '"\\e910"',
          },
          'font-size': {
            v: '12px',
          },
          'font-family': {
            v: 'OmCustom',
          },
        },
      };
      break;
  }

  if (element.desktop.alignPicker === 'top') {
    _set(rules, `${selector} label:before.margin-top.v`, `${-((height * 2) / 3)}px`);
    _set(rules, `${selector} label:after.margin-top.v`, `${-((height * 2) / 3)}px`);
    _set(rules, `${selector} input:checked ~ label:after.top.v`, 'auto');
    _set(rules, `${selector} input:checked ~ label:after.transform.v`, 'none');
  }

  if (element.desktop.alignPicker === 'bottom') {
    _set(rules, `${selector} label:before.margin-bottom.v`, `${-((height * 2) / 3)}px`);
    _set(rules, `${selector} label:after.margin-bottom.v`, `${-((height * 2) / 3)}px`);
    _set(rules, `${selector} input:checked ~ label:after.top.v`, 'auto');
    _set(rules, `${selector} input:checked ~ label:after.transform.v`, 'none');
  }

  return rules;
}

export default (element, template) => {
  const { uid, type } = element;

  const selector = `${_selector(uid, type)} > div`;
  const { desktop: _desktop, mobile: _mobile } = General(element, template, selector, [
    'margin',
    'justifyContent',
  ]);

  const styles = { mobile: _mobile, desktop: _desktop };
  correctHeight(selector, element, styles);
  correctBorder(selector, element, styles);
  correctSize(selector, element, styles);
  correctTextAlign(selector, element, styles);

  const wrapperRules = getWrapperRules(element, template);
  _merge(_desktop, wrapperRules.desktop);
  _merge(_mobile, wrapperRules.mobile);

  _merge(
    _desktop,
    getPickerRules(element, template),
    // flexDirection(element),
    margin(uid, type, element.desktop.margin, false, template, false),
  );

  _merge(_mobile, margin(uid, type, element.mobile.margin, true, template, false));

  const wrapperSelector = `${_selector(uid, type)}_wrapper`;

  _merge(
    _desktop,
    generators.justifyContent(
      uid,
      type,
      element.desktop.justifyContent,
      false,
      template,
      wrapperSelector,
      element.desktop.smartSize.type !== '100w',
    ),
  );

  _merge(
    _mobile,
    generators.justifyContent(
      uid,
      type,
      element.mobile.justifyContent,
      true,
      template,
      wrapperSelector,
      element.mobile.smartSize.type !== '100w',
    ),
  );

  return {
    desktop: _desktop,
    mobile: _mobile,
  };
};
