import { get as _get, merge as _merge } from 'lodash-es';

import { _selector, campaignScope } from './helpers';
import General from './General';

export default (element, template) => {
  const { desktop: _desktop, mobile: _mobile } = General(element, template);

  const { uid, type } = element;
  const selector = _selector(uid, type);
  const isReversedOnMobile = _get(element, 'mobile.reverseOrder', false);

  if (isReversedOnMobile) {
    const selectorCol = `${selector} .canv-col`;
    _merge(_mobile, {
      [selector]: {
        height: { v: 'auto', i: true },
        'flex-direction': { v: 'column-reverse', i: true },
      },
      [selectorCol]: {
        flex: { v: 'auto', i: true },
      },
    });
  }

  const canvasContentSelector = `${campaignScope} #${element.pageId} .om-canvas-content`;
  const hoverRowSelector = `${canvasContentSelector} .hover-row`;

  if (_get(element, 'desktop.heightType') === '100h') {
    _merge(_desktop, {
      [selector]: {
        height: { v: '100%', i: true },
        'min-height': { v: 'auto' },
      },
      [canvasContentSelector]: {
        height: { v: '100%', i: true },
      },
      [hoverRowSelector]: {
        height: { v: '100%', i: true },
      },
    });
  }

  return {
    desktop: _desktop,
    mobile: _mobile,
  };
};
