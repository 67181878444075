import { campaignScope } from './helpers';

export default (element) => {
  const _desktop = {
    [`${campaignScope} .om-scratch-card.inactive .om-scratch-wrapper:after`]: {
      content: {
        v: `"${element.data.message}"`,
      },
    },
  };

  return {
    desktop: _desktop,
  };
};
