import { get as _get, merge as _merge } from 'lodash-es';
import { _selector, fontSize, campaignScope } from '../helpers';

import General from './General';

export default (subElement, subElementType, element, template) => {
  const { desktop, mobile } = General(subElement, subElementType, element, template);

  const { uid, type } = element;
  const baseSelector = _selector(uid, type);
  const selector = `${baseSelector} ${subElement.selector}`;

  if (subElementType === 'option') {
    const generated =
      desktop[
        `${campaignScope} [data-margin="ele_ma_${uid.replace('ele_', '')}"] .om-survey-option`
      ];

    if (generated['font-size']) {
      delete generated['font-size'];
    }

    _merge(
      desktop,
      fontSize(
        uid,
        type,
        _get(element, 'subElements.option.desktop.fontSize'),
        false,
        template,
        `${selector} label span`,
      ),
    );
  }

  return {
    desktop,
    mobile,
  };
};
