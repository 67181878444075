import { merge as _merge } from 'lodash-es';
import * as generators from './helpers';
import { campaignScope } from '../util';

const setStyles = (element, template, fromObj, toObj, device) => {
  const { uid, type } = element;

  Object.keys(fromObj).forEach((prop) => {
    const generator = generators[prop];

    if (generator) {
      const uniqueSelector =
        prop === 'fontMultiplier'
          ? `${generators._selector(uid, type)} .om-dtr-content`
          : undefined;
      let styles;

      styles = generator(uid, type, fromObj[prop], device === 'mobile', template, uniqueSelector);

      if (prop === 'justifyContent') {
        styles = generators.justifyContent(
          uid,
          type,
          fromObj[prop],
          device === 'mobile',
          template,
          `${campaignScope} #${element.uid}_wrapper`,
          true, // add display flex
        );
      }

      if (prop === 'textAlign') {
        styles = generators.textAlign(
          uid,
          type,
          `${fromObj[prop]} !important`,
          device === 'mobile',
          template,
          `${generators._selector(uid, type)}.om-button .om-dtr-content`,
        );
      }

      _merge(toObj, styles);
    }
  });
};

export default (element, template) => {
  const { desktop, mobile } = element;
  const _desktop = {};
  const _mobile = {};

  setStyles(element, template, desktop, _desktop, 'desktop');
  setStyles(element, template, mobile, _mobile, 'mobile');

  return {
    desktop: _desktop,
    mobile: _mobile,
  };
};
