import { merge as _merge } from 'lodash-es';
import { _selector } from './helpers';
import General from './General';

export default (element, template) => {
  const { desktop: _desktop, mobile: _mobile } = General(element, template);

  const { uid, type } = element;
  const selector = _selector(uid, type);

  if (element.desktop.maxWidth) {
    _merge(_desktop, {
      [selector]: {
        'max-width': { v: `${element.desktop.maxWidth}%` },
        'flex-basis': { v: `${element.desktop.maxWidth}%` },
      },
    });
  }

  return {
    desktop: _desktop,
    mobile: _mobile,
  };
};
