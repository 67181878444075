import { merge as _merge } from 'lodash-es';
import components from './css/v2';
import { join } from './css/v2/helpers';
import Global from './css/v2/Global';

const _mergeRules = (desktop, mobile, mq) => {
  let mergedRules = '';
  const hover = {};

  if (desktop) {
    for (const selector in desktop) {
      const rules = desktop[selector];
      const hasRules = Object.keys(rules).length;

      if (!hasRules) {
        continue;
      }

      if (selector.includes('hover')) {
        _merge(hover, { [selector]: rules });
      } else {
        mergedRules += `${selector}{${join(rules)}}`;
      }
    }
  }

  const hoverSelectors = Object.keys(hover);
  if (hoverSelectors.length) {
    let hoverRules = '';
    for (const selector in hover) {
      const rules = hover[selector];
      hoverRules += `${selector}{${join(rules)}}`;
    }
    if (hoverRules) mergedRules += `@media (hover: hover) and (pointer: fine) {${hoverRules}}`;
  }

  if (mobile) {
    for (const selector in mobile) {
      const rules = mobile[selector];
      if (!selector.includes('hover') && Object.keys(rules).length) {
        mergedRules += `@media screen and (max-width: 576px){${selector}{${join(rules)}}}`;
      }
    }
  }

  if (mq) {
    for (const mediaQuery in mq) {
      for (const selector in mq[mediaQuery]) {
        const rules = mq[mediaQuery][selector];
        if (Object.keys(rules).length) {
          mergedRules += `${mediaQuery}{${selector}{${join(rules)}}}`;
        }
      }
    }
  }

  return mergedRules;
};

const element = (uid, template) => {
  let element = null;
  let result = '';

  if (typeof uid === 'string') {
    if (!template) return result;

    const { elements } = template;

    if (!elements) return result;

    element = elements.find((element) => element.uid === uid);
  } else {
    element = uid;
  }

  if (element) {
    const generator = components[element.type] || components.General;

    if (!generator) return result;

    const { desktop: _desktop, mobile: _mobile, mq: _mq } = generator(element, template, element);
    if (element.subElements) {
      const { desktop, mobile, mq } = components.SubGenerator(element, template);

      _merge(_desktop, desktop);
      _merge(_mobile, mobile);
      _merge(_mq, mq);
    }

    result = _mergeRules(_desktop, _mobile, _mq);
  }

  return result;
};

const template = (template, needElements = false) => {
  if (!template) return '';

  let _desktop = {};
  let _mobile = {};
  let _mq = {};

  const { mobile, desktop, mq } = Global(template);

  _merge(_desktop, desktop);
  _merge(_mobile, mobile);
  _merge(_mq, mq);

  if (needElements) {
    const { elements } = template;

    if (!elements) return _mergeRules(_desktop, _mobile, _mq);

    elements.forEach((element) => {
      const type = element.type;
      const generator = components[type] || components.General;

      if (generator) {
        const { desktop, mobile, mq } = generator(element, template);

        _desktop = _merge(_desktop, desktop);
        _mobile = _merge(_mobile, mobile);
        _mq = _merge(_mq, mq);
      }

      if (element.subElements) {
        const { desktop, mobile, mq } = components.SubGenerator(element, template);

        _desktop = _merge(_desktop, desktop);
        _mobile = _merge(_mobile, mobile);
        _mq = _merge(_mq, mq);
      }
    });
  }

  return _mergeRules(_desktop, _mobile, _mq);
};

const generateElementStyles = (template, dispatch, inDocker) => {
  const allStyles = [];
  if (inDocker) return;
  if (template.elements) {
    template.elements.forEach((el) => {
      const styles = element(el, template);

      if (styles) {
        allStyles.push(styles);
        if (dispatch) {
          dispatch('updateStylesheet', { uid: el.uid, styles });
        }
      }
    });
  }
  return allStyles;
};

export default { template, element, generateElementStyles, _mergeRules };
