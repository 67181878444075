import { merge as _merge, set as _set } from 'lodash-es';
import { pixelToEm } from '@/editor/services/css/util';
import { margin } from '@/editor/services/css/v2/helpers';

const TEASER_WRAPPER_SELECTOR = '#om-campaign-0 .om-tab-wrapper-v2';
const TEASER_CANVAS_SELECTOR = `${TEASER_WRAPPER_SELECTOR} .om-canvas`;

const setTeaserProperty = (target, selector, property, value, important) => {
  _set(target, `["${selector}"].${property}`, {
    v: value,
    i: important,
  });
};

const setDesktopTeaserCss = ({ targetDevice, element, template }) => {
  const { uid, type } = element;
  let position = element.data.position;
  if (!position || typeof position === 'number') {
    position = 'top-left';
  }
  const height = element.desktop.computedHeight;
  const positions = position.split('-');
  const distanceFromTheEdge = element.desktop.margin[positions[0]];

  if (positions[1] === 'center' && (positions[0] === 'left' || positions[0] === 'right')) {
    // Center Vertical positioning (left, right)
    const calculatedTransformValue =
      positions[0] === 'right'
        ? `rotate(270deg) translate(calc(50% - ${height}px/2), -50%) !important`
        : `rotate(270deg) translate(calc(-50% - ${height}px/2), 50%) !important`;

    setTeaserProperty(targetDevice, TEASER_WRAPPER_SELECTOR, 'bottom', '50%');
    setTeaserProperty(targetDevice, TEASER_WRAPPER_SELECTOR, 'transform', calculatedTransformValue);
    setTeaserProperty(targetDevice, TEASER_WRAPPER_SELECTOR, 'transform-origin', positions[0]);
    setTeaserProperty(targetDevice, TEASER_WRAPPER_SELECTOR, positions[0], 0);
    setTeaserProperty(
      targetDevice,
      TEASER_WRAPPER_SELECTOR,
      'margin',
      `0 ${pixelToEm(distanceFromTheEdge)}em 0 ${pixelToEm(distanceFromTheEdge)}em`,
    );
  } else if (positions[1] === 'center' && (positions[0] === 'top' || positions[0] === 'bottom')) {
    // Center Horizontal positioning (top, bottom)
    setTeaserProperty(targetDevice, TEASER_WRAPPER_SELECTOR, 'left', 0);
    setTeaserProperty(targetDevice, TEASER_WRAPPER_SELECTOR, 'right', 0);
    setTeaserProperty(targetDevice, TEASER_WRAPPER_SELECTOR, positions[0], 0);
    setTeaserProperty(targetDevice, TEASER_CANVAS_SELECTOR, 'margin', '0 auto');
    setTeaserProperty(targetDevice, TEASER_CANVAS_SELECTOR, 'pointer-events', 'all');
    setTeaserProperty(targetDevice, TEASER_WRAPPER_SELECTOR, 'pointer-events', 'none');
    setTeaserProperty(
      targetDevice,
      TEASER_WRAPPER_SELECTOR,
      'margin',
      `${pixelToEm(distanceFromTheEdge)}em 0 ${pixelToEm(distanceFromTheEdge)}em 0`,
    );
  } else {
    setTeaserProperty(targetDevice, TEASER_WRAPPER_SELECTOR, positions[0], 0);
    setTeaserProperty(targetDevice, TEASER_WRAPPER_SELECTOR, positions[1], 0);
    _merge(
      targetDevice,
      margin(
        uid,
        type,
        element.desktop.margin,
        false,
        template,
        TEASER_WRAPPER_SELECTOR,
        true,
        positions,
      ),
    );
  }
};

const setMobileTeaserCss = ({ targetDevice, element }) => {
  let position = element.data.mobilePosition;

  if (!position) {
    position = 'top';
  }

  const height = element.desktop.computedHeight;
  const positions = position.split('-');
  const distanceFromTheEdge = element.mobile.margin[positions[0]];
  const teaserMobileWidth = element.mobile.teaserWidth;

  // Vertical positioning (left, right)
  if (['left', 'right'].includes(positions[0])) {
    setTeaserProperty(targetDevice, TEASER_WRAPPER_SELECTOR, 'transform-origin', positions[0]);
    setTeaserProperty(targetDevice, TEASER_WRAPPER_SELECTOR, 'width', `${teaserMobileWidth}%`);
    setTeaserProperty(targetDevice, TEASER_WRAPPER_SELECTOR, 'top', 'unset');
    setTeaserProperty(targetDevice, TEASER_WRAPPER_SELECTOR, 'bottom', '50%');
    setTeaserProperty(targetDevice, TEASER_WRAPPER_SELECTOR, positions[0], 0);
    setTeaserProperty(
      targetDevice,
      TEASER_CANVAS_SELECTOR,
      'margin',
      `${pixelToEm(distanceFromTheEdge)}em 0 ${pixelToEm(distanceFromTheEdge)}em 0`,
      true,
    );

    if (positions[0] === 'right') {
      setTeaserProperty(targetDevice, TEASER_WRAPPER_SELECTOR, 'left', 'unset');
      setTeaserProperty(
        targetDevice,
        TEASER_WRAPPER_SELECTOR,
        'transform',
        `rotate(270deg) translate(calc(50% - ${height}px/2 - ${pixelToEm(
          distanceFromTheEdge,
        )}em), -50%) !important`,
      );
    } else {
      setTeaserProperty(targetDevice, TEASER_WRAPPER_SELECTOR, 'right', 'unset');
      setTeaserProperty(
        targetDevice,
        TEASER_WRAPPER_SELECTOR,
        'transform',
        `rotate(270deg) translate(calc(-50% - ${height}px/2 - ${pixelToEm(
          distanceFromTheEdge,
        )}em), 50%) !important`,
      );
    }
  } else {
    // Horizontal positioning (top, bottom)
    setTeaserProperty(targetDevice, TEASER_WRAPPER_SELECTOR, positions[0], '0');
    setTeaserProperty(
      targetDevice,
      TEASER_WRAPPER_SELECTOR,
      'width',
      `${teaserMobileWidth}%`,
      true,
    );
    setTeaserProperty(targetDevice, TEASER_WRAPPER_SELECTOR, 'transform', 'unset', true);
    setTeaserProperty(targetDevice, TEASER_WRAPPER_SELECTOR, 'transform-origin', 'unset');
    setTeaserProperty(targetDevice, TEASER_WRAPPER_SELECTOR, 'left', '0');
    setTeaserProperty(targetDevice, TEASER_WRAPPER_SELECTOR, 'right', '0');
    setTeaserProperty(targetDevice, TEASER_WRAPPER_SELECTOR, 'bottom', 'unset');
    setTeaserProperty(targetDevice, TEASER_WRAPPER_SELECTOR, 'margin', '0 auto');

    if (positions[0] === 'bottom') {
      setTeaserProperty(targetDevice, TEASER_WRAPPER_SELECTOR, 'top', 'unset');
      setTeaserProperty(targetDevice, TEASER_WRAPPER_SELECTOR, 'bottom', '0');
    }
  }
};

export const setTeaserPosition = ({ element, _desktop, _mobile, selector, template }) => {
  setTeaserProperty(_mobile, TEASER_WRAPPER_SELECTOR, 'width', 100);
  _set(_mobile, `["${TEASER_WRAPPER_SELECTOR}"].width`, {
    v: '100%',
    i: true,
  });
  _set(_mobile, `["${TEASER_WRAPPER_SELECTOR}"].margin`, {
    v: 0,
    i: false,
  });
  _set(_mobile, `["${selector}"].width`, { v: 'auto', i: true });

  delete _desktop[selector].margin;
  delete _desktop[selector]['margin-left'];
  delete _desktop[selector]['margin-right'];
  delete _desktop[selector]['margin-top'];
  delete _desktop[selector]['margin-bottom'];

  setDesktopTeaserCss({
    element,
    targetDevice: _desktop,
    template,
  });
  setMobileTeaserCss({
    element,
    targetDevice: _mobile,
  });
};
