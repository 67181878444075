import { get as _get, merge as _merge } from 'lodash-es';
import {
  campaignScope,
  _simpleValue,
  _color,
  fontSizeWithPixel,
  fontFamily,
  textWeight,
  fontItalic,
  textDecoration,
} from './helpers';
import General from './General';

export default (element, template) => {
  const { desktop: _desktop, mobile: _mobile } = General(element, template);

  const { uid, type } = element;

  const baseSelector = `${campaignScope} .om-lucky-wheel`;

  let colors = [];
  if (template.palette) {
    colors = [...template.palette];
  }
  if (template.style?.palette?.themeColors) {
    colors = [...colors, ...template.style.palette.themeColors.map(({ themeColor }) => themeColor)];
  }

  const getSectorRules = () => {
    const sectorRules = [];

    const classNamesByIndex = {
      1: 'first',
      2: 'second',
      3: 'third',
      4: 'fourth',
    };

    for (let i = 1; i <= 4; i++) {
      sectorRules.push(
        _simpleValue(
          uid,
          type,
          _color(element.desktop.luckyWheel[`sliceColor${i}`], colors),
          'fill',
          `${baseSelector} .om-lucky-wheel-sector-${classNamesByIndex[i]}`,
        ),
      );
    }

    return sectorRules;
  };

  _merge(
    _desktop,
    ...getSectorRules(),
    _simpleValue(
      uid,
      type,
      _color(element.desktop.luckyWheel.labelTextColor, colors),
      'fill',
      `${baseSelector} #om-lucky-wheel-labels`,
    ),
    _simpleValue(
      uid,
      type,
      _color(element.desktop.luckyWheel.backgroundFillColor, colors),
      'background',
      baseSelector,
    ),
    _simpleValue(
      uid,
      type,
      _color(element.desktop.luckyWheel.dots, colors),
      'fill',
      `${baseSelector} .om-lucky-wheel-dot-color`,
    ),
    _simpleValue(
      uid,
      type,
      _color(element.desktop.luckyWheel.sticks, colors),
      'fill',
      `${baseSelector} .om-lucky-wheel-stick-color`,
    ),
    _simpleValue(
      uid,
      type,
      _color(element.desktop.luckyWheel.radius, colors),
      'fill',
      `${baseSelector} .om-lucky-wheel-circle-color`,
    ),
    _simpleValue(
      uid,
      type,
      _color(element.desktop.luckyWheel.ticker, colors),
      'fill',
      `${baseSelector} .om-lucky-wheel-ticker-color`,
    ),
    fontSizeWithPixel(
      uid,
      type,
      _get(element, 'desktop.fontSize'),
      false,
      template,
      `${baseSelector} #om-lucky-wheel-labels .om-lucky-wheel-text`,
    ),
    fontFamily(
      uid,
      type,
      _get(element, 'desktop.fontFamily'),
      false,
      template,
      `${baseSelector} #om-lucky-wheel-labels .om-lucky-wheel-text`,
    ),
    textWeight(
      uid,
      type,
      _get(element, 'desktop.textWeight'),
      false,
      template,
      `${baseSelector} #om-lucky-wheel-labels .om-lucky-wheel-text`,
    ),
    fontItalic(
      uid,
      type,
      _get(element, 'desktop.fontItalic'),
      false,
      template,
      `${baseSelector} #om-lucky-wheel-labels .om-lucky-wheel-text`,
    ),
    textDecoration(
      uid,
      type,
      _get(element, 'desktop.textDecoration'),
      false,
      template,
      `${baseSelector} #om-lucky-wheel-labels .om-lucky-wheel-text`,
    ),
  );

  return {
    desktop: _desktop,
    mobile: _mobile,
  };
};
