import { merge as _merge } from 'lodash-es';
import { campaignScope, textAlign, smartSize, _selector, _simpleValue } from './helpers';
import General from './General';

export default (element, template) => {
  const { desktop: _desktop, mobile: _mobile } = General(element, template, ['smartSize']);

  const { uid, type } = element;
  const selector = `${campaignScope} #${uid}`;
  const alignSelector = `${selector}_align`;

  const comparisonRatio = '4.29vmin';

  delete _desktop[selector]?.background;

  _merge(_desktop, textAlign(uid, type, element.desktop.align, false, template, alignSelector));

  _merge(
    _mobile,
    smartSize(uid, type, element.desktop.smartSize, false, template, _selector(uid, type)),
  );

  _merge(_mobile, textAlign(uid, type, element.mobile.align, true, template, alignSelector));

  _merge(
    _mobile,
    smartSize(
      uid,
      type,
      element.mobile.smartSize,
      true,
      template,
      _selector(uid, type),
      element.mobile?.calcWidthInEm ?? false,
    ),

    _simpleValue(
      uid,
      type,
      element.mobile?.calcWidthInEm ? comparisonRatio : null,
      'font-size',
      `${alignSelector}`,
    ),
  );

  return {
    desktop: _desktop,
    mobile: _mobile,
  };
};
