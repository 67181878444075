import { merge as _merge } from 'lodash-es';
import { isNano } from '@/editor/util';
import { _selector, _simpleValue, _simpleEmTransform } from './helpers';
import General from './General';

export default (element, template) => {
  const { desktop: _desktop, mobile: _mobile } = General(element, template);

  const { uid, type } = element;

  _merge(
    _desktop,
    _simpleValue(uid, type, 'none', 'display', `${_selector(uid, type)} input`),
    _simpleEmTransform(
      uid,
      type,
      element.desktop.horizontalSpacing,
      element.data.displayType === 'stars' ? 'padding-left' : 'margin-right',
      `${_selector(uid, type)} .om-feedback-option`,
    ),
  );

  _merge(
    _mobile,
    _simpleEmTransform(
      uid,
      type,
      element.mobile.horizontalSpacing,
      element.data.displayType === 'stars' ? 'padding-left' : 'margin-right',
      `${_selector(uid, type)} .om-feedback-option`,
    ),
  );

  _merge(
    _desktop,
    _simpleEmTransform(
      uid,
      type,
      element.subElements.question.desktop.verticalSpacing,
      isNano(template.style.mode) ? 'margin-left' : 'margin-top',
      `${_selector(uid, type)} .om-feedback-question + div`,
    ),
  );

  _merge(
    _mobile,
    _simpleEmTransform(
      uid,
      type,
      element.subElements.question.desktop.verticalSpacing,
      'margin-top',
      `${_selector(uid, type)} .om-feedback-question + div`,
    ),
    isNano(template.style.mode)
      ? {
          [`${_selector(uid, type)} .om-feedback-question + div`]: {
            'margin-left': { v: 0, i: true },
          },
        }
      : {},
  );

  return {
    desktop: _desktop,
    mobile: _mobile,
  };
};
