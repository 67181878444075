<template lang="pug">
.form-modal(v-if="showFormManager.pickAPresentTest" @mousedown="hide")
  .content.om-pick-a-present-test-modal(@mousedown.stop="")
    .header.om-bottom-divider.pb-4
      .om-row.header-inner
        .om-col.om-col-7
          .om-title-field {{ $t('pickAPresent.test.title') }}
      .button.om-close(@click="hide" style="position: absolute; top: 0; right: 0") &times;
    .om-pick-a-present-test(:key="componentId" :style="padding")
      om-pick-a-present(:item="item")
      component(is="style") {{ styles }}
    .om-row.footer(style="position: relative")
      .button.button-link.mr-3(@click="hide") {{ $t('close') }}
      .button.button-primary.button-large(@click="reset") {{ $t('reset') }}
</template>

<script>
  import { nanoid } from 'nanoid';
  import { mapState, mapMutations } from 'vuex';
  import CssGenerator from '@/editor/services/CssGenerator';
  import getStyles from '@/editor/services/css/v2/OmPickAPresent';
  import { pixelToEm } from '@/editor/services/css/util';
  import { randomArrayIndex } from '@/util';
  import { get as _get } from 'lodash-es';
  import { _getTemplateColors } from '../../services/css/v2/helpers';
  import { generatePaletteColorsClasses } from '../../services/css/v2/Global';

  const _clone = (v) => JSON.parse(JSON.stringify(v));

  const frameStore = () => {
    const workspace = document.getElementById('workspaceFrame');
    return _get(workspace, 'contentWindow.om.store');
  };

  export default {
    components: {
      OmPickAPresent: () => import('@/editor/components/elements/OmPickAPresent.vue'),
    },

    data() {
      return {
        currentPage: null,
        item: null,
        componentId: null,
      };
    },

    computed: {
      ...mapState([
        'showFormManager',
        'selectedElement',
        'globalStyle',
        'selectedPage',
        'pickAPresentOptions',
      ]),

      styles() {
        let css;
        let templateColorsCss;

        const store = frameStore();
        const themeColors = store.state.template.style.palette.themeColors;
        if (store) {
          const colors =
            themeColors.length === 0
              ? _getTemplateColors(store.state.template)
              : _getTemplateColors(store.state.template)
                  .replace(/(#\w{6})(?=#\w{6})/g, '$1,')
                  .split(',');
          const rules = generatePaletteColorsClasses(colors, '.om-pick-a-present-test');
          templateColorsCss = CssGenerator._mergeRules(rules);
          const { desktop, mobile } = getStyles(this.item, store.state.template);
          css = CssGenerator._mergeRules(desktop, mobile);
          css = css.replace(new RegExp('#om-campaign-0', 'g'), '.om-pick-a-present-test');
        }
        return css + templateColorsCss;
      },

      padding() {
        const { top, bottom, left, right, allSides } = this.currentPage.desktop.padding;

        if (allSides === true && top === 0) {
          return {
            padding: '1em',
          };
        }

        return {
          'padding-top': `${pixelToEm(top)}em`,
          'padding-bottom': `${pixelToEm(bottom)}em`,
          'padding-left': `${pixelToEm(left)}em`,
          'padding-right': `${pixelToEm(right)}em`,
        };
      },
    },

    watch: {
      'showFormManager.pickAPresentTest': function (v) {
        if (v) {
          this.reset();
        }
      },
    },

    created() {
      this.init();
    },

    mounted() {
      if (this.showFormManager.pickAPresentTest) {
        this.reset();
      }
    },

    methods: {
      ...mapMutations(['changeFormManagerVisibility']),
      init() {
        this.componentId = nanoid(9);
        const store = frameStore();

        if (store) {
          store.state.template.elements.forEach((e) => {
            if (this.selectedPage.uid === e.uid) {
              this.currentPage = _clone(e);
            }

            if (this.selectedPage.uid === e.pageId && e.type === 'OmPickAPresent') {
              this.item = _clone(e);
            }
          });
        }
      },
      hide() {
        this.changeFormManagerVisibility({ hide: 'pickAPresentTest' });
      },
      getRandomCoupon() {
        const coupons = [];

        this.pickAPresentOptions.forEach((o) => {
          for (let i = 0; i < o.weigth; i++) {
            coupons.push({ title: o.title, code: o.couponCode });
          }
        });

        const randomIndex = randomArrayIndex(coupons.length);
        const coupon = coupons[randomIndex];

        return coupon;
      },
      reset() {
        this.init();
        this.$nextTick(async () => {
          const { default: PickAPresent } = await import('@/editor/gamification/pickAPresent');
          new PickAPresent(this.getRandomCoupon());
        });
      },
    },
  };
</script>

<style lang="sass">
  .om-pick-a-present
    display: flex
    justify-content: center
    align-items: center
    position: relative
    z-index: 1

    .om-present
      &-active
        cursor: pointer

        svg
          &:hover
            transform: scale(1.1)

      &:last-child
        margin-right: 0 !important

      svg
        -webkit-backface-visibility: hidden
        overflow: visible
        position: relative
        z-index: 1
        transition: transform .3s

      &-coupon-holder
        text-align: center
        position: absolute
        top: 50%
        left: 0
        right: 0
        z-index: 0
        transform: scale(0)

        &.om-present-coupon-long-title
          text-align: left
          display: flex
          justify-content: flex-start
          overflow: hidden
          div
            white-space: normal
            word-break: break-word
        div
          text-align: center
          font-weight: bold
          font-size: 2em
          white-space: nowrap
          display: flex
          justify-content: center
          display: inline-block
  .om-pick-a-present-test-modal
    min-height: auto !important

  .om-pick-a-present
    &-test
      display: flex
      justify-content: center
      align-items: center
      width: 100%
      min-height: 20rem
      > div
        width: 100%
</style>
