import { get as _get, merge as _merge } from 'lodash-es';

import * as generators from './helpers';

const fixMobileRulePropValues = (element, rule, mobileProps) => {
  let result = mobileProps;

  if (result) {
    const desktopProps = _get(element, `desktop.${rule}`);

    if (rule === 'border' && result && desktopProps) {
      result = _merge({}, result, {
        width: result.width === null ? desktopProps.width : result.width,
        color: result.color === null ? desktopProps.color : result.color,
      });
    }
  }

  return result;
};

export default (element, template, uniqueSelector = undefined, skip = []) => {
  const { uid, type, desktop, mobile } = element;
  const _desktop = {};
  const _mobile = {};

  Object.keys(desktop).forEach((prop) => {
    if (skip.includes(prop)) return;
    const generator = generators[prop];

    if (generator) {
      const styles = generator(uid, type, desktop[prop], false, template, uniqueSelector);
      _merge(_desktop, styles);
    }
  });

  Object.keys(mobile).forEach((prop) => {
    if (skip.includes(prop)) return;
    const generator = generators[prop];

    if (generator) {
      const props = fixMobileRulePropValues(element, prop, mobile[prop]);
      const styles = generator(uid, type, props, true, template, uniqueSelector);

      _merge(_mobile, styles);
    }
  });

  return {
    desktop: _desktop,
    mobile: _mobile,
  };
};
