import { merge as _merge } from 'lodash-es';
import * as helpers from '../helpers';

export default (style, template) => {
  const selectorWrapper = '#om-campaign-0 .om-tab-wrapper';
  const selector = '#om-campaign-0 .om-tab';
  const type = 'tab';
  const _desktop = {};
  const skippedRules = ['fontSize'];

  // GENERATE REGULAR RULES
  for (const key in style) {
    if (skippedRules.includes(key)) continue;
    const generator = helpers[key];
    if (!generator) continue;
    const value = style[key];

    _merge(_desktop, generator(type, type, value, false, template, selector));
  }

  // BASIC SETTINGS
  const __position =
    style.position || (style.positions ? style.positions.position : undefined) || 'top-left';
  const __pos = __position.split('-');
  const __displayType = style.type.style;
  const __size = parseInt(style.type.size, 10);
  const __fontSize = style.fontSize;
  const __sizeEm = helpers._pixelToEm(__size, __fontSize);
  const __align = 'center';

  if (__displayType === 'corner') {
    const _modSizeEm = __sizeEm * 2.5;
    const _flexAlign = __pos[0] === 'bottom' ? 'start' : 'end';
    const _seekerType = style.animation.attentionSeeker.type;
    const _transform = ['bottom-right', 'top-left'].includes(__position) ? -45 : 45;

    _merge(_desktop, {
      [selectorWrapper]: {
        width: { v: `${_seekerType ? _modSizeEm * 1.25 : _modSizeEm}em` },
        height: { v: `${_modSizeEm}em` },
        [__pos[0]]: { v: '0' },
        [__pos[1]]: { v: '0' },
      },
      [selector]: {
        transform: { v: `rotate(${_transform}deg)` },
        padding: { v: `${helpers._pixelToEm((__size - 50) * 0.2 + 10, __fontSize)}em` },
        position: { v: 'absolute' },
        [__pos[0]]: { v: '-50%' },
        [__pos[1]]: { v: '-50%' },
        'align-items': { v: `flex-${_flexAlign}` },
        '-ms-flex-align': { v: _flexAlign },
      },
    });
  } else if (__displayType === 'floating') {
    const _multiplier = (__size - 60) * 0.00625 + 1;
    const _floatingSize = (__size + 10) * _multiplier;
    const size = `${helpers._pixelToEm(_floatingSize, __fontSize)}em`;

    _merge(_desktop, {
      [selectorWrapper]: {
        [__pos[0]]: { v: '10px' },
        [__pos[1]]: { v: '10px' },
      },
      [selector]: {
        width: { v: size },
        'min-width': { v: size },
        height: { v: size },
        padding: { v: `${helpers._pixelToEm((__size / 12) * _multiplier)}em` },
        'line-height': { v: '1em', i: true },
        'border-radius': { v: '100%' },
        'align-items': { v: __align },
        '-ms-flex-align': { v: __align },
      },
    });
  } else if (__displayType === 'basic') {
    const _size = __size - 50;
    const w = `${helpers._pixelToEm(145 + _size, __fontSize)}em`;
    const borderRadius = __pos[0] === 'top' ? '0 0 7px 7px' : '7px 7px 0 0';

    _merge(_desktop, {
      [selectorWrapper]: {
        [__pos[0]]: { v: '0' },
        [__pos[1]]: { v: '10px' },
      },
      [selector]: {
        width: { v: w },
        'min-width': { v: w },
        height: { v: `${helpers._pixelToEm(45 + _size * 0.4, __fontSize)}em` },
        padding: { v: `${helpers._pixelToEm(5 + _size * 0.1, __fontSize)}em` },
        'border-radius': { v: borderRadius },
        'align-items': { v: __align },
        '-ms-flex-align': { v: __align },
      },
    });
  }

  return {
    desktop: _desktop,
    mobile: {},
  };
};
